.calculate-page {
	width: 100%;
	height: 100vh;

	.tooltip {
		display: none;
		overflow: hidden;
	}
	input[type="number"] {
		-webkit-appearance: none;
		-moz-appearance: textfield;
		appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	h1 {
		color: var(--color-white);
		margin-bottom: 60px;
		max-width: 85%;
	}
	padding-top: 80px;

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000;
		display: block; // Change this from 'none' to 'block'
	}

	.navbar-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.navigation {
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			&.error {
				border: 2px solid red;
			}
		}
	}

	.question-mark {
		.bi-question-circle {
			font-size: 1.3vw;
			color: #888;
			cursor: default;
			display: flex;
		}
	}

	.slide {
		color: var(--color-white);
		position: relative;
		padding-bottom: 30px;
	}
	.slide-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 25vw);
	}
	.slide-container2 {
		padding-top: 3vw;
	}
	.popup {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1001;
		background-color: var(--color-black);
		padding: 20px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		max-width: 80%;
		width: 400px;
		border-radius: 5px;

		.close-button {
			position: absolute;
			top: 0.7vw;
			right: 0.4vw;
			font-size: 2.2vw;
			cursor: pointer;
			color: #888;

			&:hover {
				color: var(--color-white);
			}
		}
	}
	.paragraph-button {
		background-color: var(--color-white);
		border-radius: 58px;
		color: var(--color-black);
		cursor: pointer;
		font-family: "Graphik LCG", sans-serif;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.5px;
		line-height: 140%;
		padding: 10px 22px;
		text-align: center;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		width: min-content;
		transition: background-color 0.2s ease, color 0.2s ease, padding 0.2s ease;

		span {
			transition: transform 0.2s ease;
		}

		.arrow-icon {
			position: absolute;
			right: 12px;
			opacity: 0;
			transform: translateX(20px);
			top: 50%;
			margin-top: -10px; // Half of the SVG height to center it vertically
			transition: opacity 0.2s ease, transform 0.2s ease;
		}

		// &:hover {
		// 	background-color: var(--color-black);
		// 	color: var(--color-white);
		// 	border: 1px solid var(--color-white);
		// 	padding-right: 22px; // 24px original + 20px for arrow

		// 	span {
		// 		transform: translateX(-10px);
		// 	}
		// }

		&:before {
			content: "";
			position: absolute;
			right: 16px;
			top: 50%;
			margin-top: -12px;
			width: 24px;
			height: 24px;
			border: 2px solid;
			border-left-color: transparent;
			border-right-color: transparent;
			border-radius: 50%;
			opacity: 0;
			transition: opacity 0.5s ease;
			animation: 0.8s linear infinite rotate;
		}

		&.sending {
			pointer-events: none;
			cursor: not-allowed;

			&:before {
				transition-delay: 0.5s;
				opacity: 1;
			}

			&:hover {
				.arrow-icon {
					opacity: 0;
				}
			}
		}
	}
}

//-------------------------------------------- Options ---------------------------------
.options-group {
	position: relative;
	z-index: 2;
	display: flex;
	min-width: 600px;
	height: 7vw;
	background-color: #000;
	border-radius: 7px;
	padding: 4px;
	width: 80%;

	&.error {
		box-shadow: 0px 1px 15px 0px rgb(255, 0, 0);
	}

	.option-button {
		cursor: pointer;
		flex: 1;
		background-image: linear-gradient(to top, #242424 0%, #303030 100%);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 1px;
		transition: 0.2s;
		font-size: 3em;
		box-shadow: inset 0 20px 4px -21px rgba(255, 255, 255, 0.4),
			0 19px 13px 0 rgba(0, 0, 0, 0.3);
		color: #181818;
		position: relative;
		z-index: 2;
		-webkit-tap-highlight-color: transparent;
		color: var(--color-white);

		&.active {
			background-image: linear-gradient(to top, #151515 0%, #1d1d1d 100%);
			box-shadow: inset 0 16px 14px -21px transparent,
				0 0px 13px 0 rgba(0, 0, 0, 0.3), inset 0 0 7px 2px rgba(0, 0, 0, 0.4);
			z-index: 0;
			color: #76cedb;

			&:before {
				width: 1em;
				height: 1em;
				opacity: 0.8;
			}

			span {
				text-shadow: 0 1px 25px rgba(118, 206, 219, 0.5);
			}
		}
	}
	.option-button:first-child {
		border-radius: 4px 0 0 4px;
	}

	.option-button:last-child {
		border-radius: 0 4px 4px 0;
	}
	.number-option {
		color: var(--color-white);
		padding-left: 15px;
		font-size: 30px;
	}
	.number-option {
		-webkit-appearance: none;
		-moz-appearance: textfield;
		appearance: textfield;
	}

	.number-option::-webkit-inner-spin-button,
	.number-option::-webkit-outer-spin-button {
		-webkit-appearance: auto;
		margin: 0;
	}

	.number-option::-moz-number-spin-box {
		-moz-appearance: auto;
	}
}

// @keyframes cycleToRight {
// 	0% {
// 		transform: translateX(0);
// 	}
// 	50% {
// 		transform: translateX(1.1vw);
// 	}
// 	100% {
// 		transform: translateX(0);
// 	}
// }

//-------------------------------------------- Arrow ---------------------------------
.arrow-button {
	cursor: pointer;
	height: 80px;
	position: absolute;
	top: 50%;
	transform: translateY(0%);
	transition: transform 0.1s;
	width: 50px;
	background: none;
	border: none;
	margin: 0 2vw;
}

.arrow-button:active {
	transform: translateY(0%) scale(0.9);
}

.arrow-button .arrow-top,
.arrow-button .arrow-bottom {
	background-color: #666;
	height: 4px;
	left: -5px;
	position: absolute;
	top: 50%;
	width: 100%;
}

.arrow-button .arrow-top:after,
.arrow-button .arrow-bottom:after {
	background-color: #fff;
	content: "";
	height: 100%;
	position: absolute;
	top: 0;
	transition: all 0.1s;
}

.arrow-button .arrow-top {
	transform: rotate(45deg);
	transform-origin: bottom right;
}

.arrow-button .arrow-bottom {
	transform: rotate(-45deg);
	transform-origin: top right;
}

.arrow-button .arrow-top:after {
	left: 100%;
	right: 0;
	transition-delay: 0s;
}

.arrow-button .arrow-bottom:after {
	left: 0;
	right: 100%;
	transition-delay: 0.1s;
}

.arrow-button:hover .arrow-top:after {
	left: 0;
	transition-delay: 0.1s;
}

.arrow-button:hover .arrow-bottom:after {
	right: 0;
	transition-delay: 0s;
}

.arrow-button--prev {
	left: 0;
}

.arrow-button--next {
	right: 0;
	transform: translateY(0) rotate(0deg);
}

.arrow-button--next:active {
	transform: translateY(0) rotate(0deg) scale(0.9);
}
.arrow--left {
	transform: rotate(180deg);
}

//---------arrow active---------
.animate-arrow .arrow-top:after {
	left: 0;
	transition-delay: 0.1s;
}

.animate-arrow .arrow-bottom:after {
	right: 0;
	transition-delay: 0s;
}
@media screen and (max-width: 991px) {
	/* Disable hover effect on .animate-arrow on mobile */
	.animate-arrow .arrow-top:after {
		left: auto; /* Reset left position */
		transition-delay: 0s; /* Reset transition delay */
	}

	.animate-arrow .arrow-bottom:after {
		right: auto; /* Reset right position */
		transition-delay: 0s; /* Reset transition delay */
	}
}
// --------------- Step 6 --------------
.service-container {
	display: flex;
	flex-direction: column;

	h2 {
		font-family: "Graphik LCG Regular", sans-serif;
		font-size: 3vw;
		margin-bottom: 2vw;
		text-align: center;
	}
}

.service-option__container {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 2.2rem;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	.service-option {
		display: flex;
		position: relative;
		flex-direction: row;
		align-items: center;
		width: 100%;
		&.main-service {
			// font-size: 2vw;
			margin-bottom: 10px;
		}
		&.main-service-name {
			font-size: 6vw;
		}
		&.sub-service {
			font-size: 1.4vw;
			margin-left: 20px;
		}

		.question-mark {
			align-self: flex-start;
			position: relative;
			// padding-left: 3px;

			&:hover::after {
				content: attr(data-tooltip);
				position: absolute;
				background-color: rgba(0, 0, 0, 0.8);
				color: var(--color-white);
				top: -50%;
				left: 50%;
				transform: translateX(0%);
				padding: 5px 10px;
				border-radius: 4px;
				font-size: 1vw;
				white-space: nowrap;
				z-index: 1000;
				width: max-content;
				max-width: fit-content;
			}
		}
		.option-name {
			// font-size: 1.6vw;
			transition-delay: 0.5s;
			// &:hover::after {
			// 	content: attr(title);
			// 	position: absolute;
			// 	background-color: rgba(0, 0, 0, 0.5);
			// 	color: var(--color-white);
			// 	padding: 5px 10px;
			// 	border-radius: 4px;
			// 	font-size: 1.6vw;
			// 	white-space: nowrap;
			// 	z-index: 1000;
			// }
		}
		.custom-checkbox {
			flex-shrink: 0;
		}
	}
}

.sub-services-title {
	font-family: "Graphik LCG Regular", sans-serif;
	font-size: 2vw;
	margin-top: 1rem;
	margin-bottom: 0.8rem;
}

// .option-name {
// 	font-size: 1.6vw;
// }
.question-mark {
	align-self: flex-start;
	position: relative;
	padding-left: 3px;
	cursor: pointer;

	@media (min-width: 769px) {
		&:hover::after {
			content: attr(data-tooltip);
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			background-color: rgba(0, 0, 0, 0.8);
			color: var(--color-white);
			padding: 8px 12px;
			border-radius: 4px;
			font-size: 14px;
			white-space: nowrap;
			z-index: 1000;
		}
	}

	@media (max-width: 768px) {
		&::after {
			display: none;
		}
	}
}

// .tooltip-active::after {
//     content: attr(data-tooltip);
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     transform: translateX(-50%);
//     background-color: rgba(0, 0, 0, 0.8);
//     color: var(--color-white);
//     padding: 8px 12px;
//     border-radius: 4px;
//     font-size: 14px;
//     white-space: nowrap;
//     z-index: 1000;
// }

.option-name {
	// font-size: 1.6vw;
	transition-delay: 0.5s;
	// &:hover::after {
	// 	content: attr(title);
	// 	position: absolute;
	// 	background-color: rgba(0, 0, 0, 0.5);
	// 	color: var(--color-white);
	// 	padding: 5px 10px;
	// 	border-radius: 4px;
	// 	font-size: 1.6vw;
	// 	white-space: nowrap;
	// 	z-index: 1000;
	// }
}

.option-name:hover {
	transition-delay: 0.5s;
}

.cost__container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 1.5vw;
	width: 90%;
}

.custom-checkbox {
	cursor: pointer;
	width: 32px;
	height: 32px;
	background-image: linear-gradient(to top, #282828 0%, #444444 100%);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	box-shadow: inset 0 20px 4px -21px rgba(255, 255, 255, 0.5),
		0 9px 13px 0 rgba(0, 0, 0, 0.5);
	margin-right: 10px;

	svg {
		opacity: 0;
	}

	&.active {
		background-image: linear-gradient(to top, #282828 0%, #333333 100%);
		box-shadow: inset 0 16px 14px -21px transparent,
			0 0px 13px 0 rgba(0, 0, 0, 0.5), inset 0 0 9px 2px rgba(0, 0, 0, 0.6);

		svg {
			opacity: 1;
			fill: #76cedb;
		}
	}
	&.error {
		border: 2px solid red;
	}
}

.additional-options {
	padding-top: 20px;
	p {
		font-family: "Graphik LCG SemiBold", sans-serif;
		font-size: 1.46vw;
		margin-bottom: 10px;
	}
	ul {
		margin-left: 50px;
	}
	.option-name {
		font-family: "Graphik LCG Regular", sans-serif;
		// font-size: 1.25vw;
	}
	.custom-checkbox {
		width: 28px;
		height: 28px;
	}
	.service-option {
		margin-bottom: 10px;
		.option-name {
			&:hover::after {
				content: none;
			}
		}
	}
}

.cost-name {
	font-family: "Graphik LCG Medium", sans-serif;
	font-size: 1.5vw;
	margin-bottom: 10px;
	text-align: right;
}

.popup {
	h2 {
		margin-bottom: 15px;
		color: var(--color-white);
		text-align: center;
		font-size: 2.2vw;
	}
	.contact-options {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 15px;

		input,
		textarea {
			flex: 1 0 calc(50% - 10px);
			position: relative;
			padding: 15px;
			font-size: 16px;
			font-family: "Graphik LCG Regular", sans-serif;
			background-color: var(--color-black-2nd);
			color: var(--color-white);
			border: 1px solid var(--color-gray);
			border-radius: 5px;
			transition: border-color 0.3s ease;

			&:focus {
				outline: none;
				border-color: var(--color-blue-light);
			}

			&::placeholder {
				color: var(--color-gray);
			}
			&.error {
				border-color: red;
			}
		}
		textarea {
			flex: 1 0 100%;
			resize: vertical;
			min-height: 100px;
		}
	}

	.contact-row {
		display: flex;
		width: 100%;
		gap: 10px;
	}

	.contact-row > * {
		flex: 1;
	}
	.submit-button {
		background-color: var(--color-white);
		border-radius: 15px;
		color: var(--color-black);
		cursor: pointer;
		font-family: "Graphik LCG", sans-serif;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.5px;
		line-height: 140%;
		padding: 10px 22px;
		text-align: center;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		width: min-content;
		transition: background-color 0.2s ease, color 0.2s ease, padding 0.2s ease;

		&:active {
			background-color: var(--color-blue-light);
			color: var(--color-black);
		}
	}
	.success-message {
		color: #76cedb;
		font-family: "Graphik LCG", sans-serif;
		font-size: 18px;
		margin-top: 1.8vw;
		text-align: center;
		padding: 10px;
		background-color: rgba(118, 206, 219, 0.1);
		border-radius: 5px;

		.bi-check-circle {
			font-size: 38px;
		}

		p {
			font-size: 1.4vw;
			padding-top: 15px;
			margin-bottom: 15px;
		}
	}
}

@media screen and (min-width: 3840px) {
	.calculate-page {
		padding-top: 2.5vw;

		h1 {
			font-size: 3vw;
			margin-bottom: 2vw;
		}
		.slide-container {
			padding-top: 0px;
			height: calc(60vh - 2.5vw); // Subtracting the padding-top
		}
		.slide-container2 {
			padding-top: 5vw;
		}
		.paragraph-button {
			font-size: 1.2vw;
			padding: 0.6vw 1.2vw;
			border-radius: 1.4vw;
		}
		.custom-checkbox {
			width: 1.5vw;
			height: 1.5vw;
			border-radius: 0.16vw;
			margin-right: 0.4vw;
		}
		.popup {
			width: 29vw;
			padding: 1.1vw;
			h2 {
				font-size: 2.6vw;
				margin-bottom: 1vw;
			}

			.contact-options {
				gap: 0.8vw;
				margin-bottom: 1vw;

				input {
					padding: 1vw;
					font-size: 1vw;
					border-radius: 0.3vw;
				}
			}

			.submit-button {
				font-size: 1vw;
				padding: 0.8vw 1.5vw;
				border-radius: 1vw;
			}

			.success-message {
				font-size: 1.2vw;
				padding: 0.8vw;
				border-radius: 0.3vw;

				.bi-check-circle {
					font-size: 2.5vw;
				}

				p {
					padding-top: 1vw;
					margin-bottom: 1vw;
				}
			}
			.close-button {
				font-size: 2vw;

				&::before,
				&::after {
					width: 1.4vw;
					height: 0.2vw;
				}
			}
		}
	}

	.options-group {
		min-width: 30vw;
		height: 6vw;

		.option-button {
			font-size: 1.8vw;
		}
		.number-option {
			padding-left: 0.8vw;
		}
	}

	.option-name {
		font-size: 2.2vw;
	}
	.sub-services-title {
		font-size: 2vw;
	}
	.additional-options {
		padding-top: 0.8vw;
		p {
			font-size: 1.5vw;
			margin-bottom: 0.4vw;
		}
		ul {
			margin-left: 2vw;
		}
		.option-name {
			font-size: 1.5vw;
		}
		.service-option {
			margin-bottom: 0.7vw;
		}
	}
	.service-option__container {
		.service-option {
			&.sub-service {
				margin-left: 50px;
			}
		}
	}

	.cost-name {
		font-size: 1.4vw;
		margin-bottom: 0.4vw;
	}

	.arrow-button {
		width: 100px;
		margin: 0 4vw;
	}
	.custom-checkbox svg {
		width: 48px;
		height: 48px;
	}
}

@media screen and (min-width: 2500px) and (max-width: 3839px) {
	.calculate-page {
		padding-top: 2.5vw;

		h1 {
			font-size: 3vw;
			margin-bottom: 2vw;
		}
		.slide-container {
			padding-top: 0px;
			height: calc(60vh - 2.5vw); // Subtracting the padding-top
		}
		.slide-container2 {
			padding-top: 5vw;
		}
		.paragraph-button {
			font-size: 1.2vw;
			padding: 0.6vw 1.2vw;
			border-radius: 1.4vw;
		}
		.custom-checkbox {
			width: 1.5vw;
			height: 1.5vw;
			border-radius: 0.16vw;
			margin-right: 0.4vw;
		}
		.popup {
			width: 29vw;
			padding: 1.1vw;
			h2 {
				font-size: 2.6vw;
				margin-bottom: 1vw;
			}

			.contact-options {
				gap: 0.8vw;
				margin-bottom: 1vw;

				input {
					padding: 1vw;
					font-size: 1vw;
					border-radius: 0.3vw;
				}
			}

			.submit-button {
				font-size: 1vw;
				padding: 0.8vw 1.5vw;
				border-radius: 1vw;
			}

			.success-message {
				font-size: 1.2vw;
				padding: 0.8vw;
				border-radius: 0.3vw;

				.bi-check-circle {
					font-size: 2.5vw;
				}

				p {
					padding-top: 1vw;
					margin-bottom: 1vw;
				}
			}
			.close-button {
				font-size: 2vw;

				&::before,
				&::after {
					width: 1.4vw;
					height: 0.2vw;
				}
			}
		}
	}

	.options-group {
		min-width: 30vw;
		height: 6vw;

		.option-button {
			font-size: 1.8vw;
		}
		.number-option {
			padding-left: 0.8vw;
		}
	}

	.option-name {
		font-size: 2.2vw;
	}
	.sub-services-title {
		font-size: 2vw;
	}
	.additional-options {
		padding-top: 0.8vw;
		p {
			font-size: 1.5vw;
			margin-bottom: 0.4vw;
		}
		ul {
			margin-left: 2vw;
		}
		.option-name {
			font-size: 1.7vw;
		}
		.service-option {
			margin-bottom: 0.7vw;
		}
	}
	.service-option__container {
		.service-option {
			&.sub-service {
				margin-left: 40px;
			}
		}
	}
	.cost-name {
		font-size: 1.6vw;
		margin-bottom: 0.4vw;
	}

	.arrow-button {
		width: 70px;
		margin: 0 4vw;
	}
}

@media screen and (max-width: 2500px) {
	.arrow-button {
		margin: 0 3vw;
	}
}

@media screen and (min-width: 1200px) {
	.service-option__container {
		.service-option {
			&.main-service .option-name {
				font-size: 2.5vw;
			}
			.option-name {
				font-size: 2vw;
			}
			.sub-services-title {
				font-size: 2vw; // Original size
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	.calculate-page {
		padding-top: 4vw;

		h1 {
			font-size: 4vw;
			margin-bottom: 3vw;
		}
		.slide-container {
			height: calc(70vh - 4vw);
		}
		.slide-container2 {
			padding-top: 12vw;
		}
		.paragraph-button {
			font-size: 1.6vw;
			padding: 0.8vw 1.6vw;
			border-radius: 1.8vw;
		}
		.custom-checkbox {
			width: 2vw;
			height: 2vw;
			border-radius: 0.2vw;
			margin-right: 0.6vw;
		}
		.popup {
			width: 40vw;
			padding: 1.5vw;
			h2 {
				font-size: 3.5vw;
				margin-bottom: 1.5vw;
			}
			.contact-options {
				gap: 1.2vw;
				margin-bottom: 1.5vw;
				input {
					padding: 1.4vw;
					font-size: 1.4vw;
					border-radius: 0.4vw;
				}
			}
			.submit-button {
				font-size: 1.4vw;
				padding: 1vw 2vw;
				border-radius: 1.4vw;
			}
			.success-message {
				font-size: 1.6vw;
				padding: 1vw;
				border-radius: 0.4vw;
				.bi-check-circle {
					font-size: 3.4vw;
				}
				p {
					padding-top: 1.4vw;
					margin-bottom: 1.4vw;
				}
			}
			.close-button {
				font-size: 2.6vw;
				&::before,
				&::after {
					width: 1.8vw;
					height: 0.3vw;
				}
			}
		}
	}

	.options-group {
		min-width: 40vw;
		height: 10vw;
		.option-button {
			font-size: 2.4vw;
		}
		.number-option {
			padding-left: 1vw;
		}
	}

	.option-name {
		font-size: 2.8vw;
	}
	.sub-services-title {
		font-size: 2.6vw;
	}
	.additional-options {
		padding-top: 1vw;
		p {
			font-size: 2vw;
			margin-bottom: 0.6vw;
		}
		ul {
			margin-left: 2.6vw;
		}
		.option-name {
			font-size: 2vw;
		}
		.service-option {
			margin-bottom: 1vw;
		}
	}

	.cost-name {
		font-size: 2vw;
		margin-bottom: 0.6vw;
	}

	.arrow-button {
		margin: 0 3vw;
		width: 40px;
	}
	.service-container h2 {
		font-size: 4vw;
		margin-bottom: 3vw;
	}
}

@media screen and (max-width: 991px) {
	.calculate-page {
		padding-top: 5vw;

		h1 {
			font-size: 4.5vw;
			margin-bottom: 4vw;
		}
		.slide-container {
			height: calc(75vh - 5vw);
		}
		.slide-container2 {
			padding-top: 14vw;
		}
		.paragraph-button {
			font-size: 2vw;
			padding: 1vw 2vw;
			border-radius: 2.2vw;
		}
		.custom-checkbox {
			width: 2.5vw;
			height: 2.5vw;
			border-radius: 0.25vw;
			margin-right: 0.8vw;
		}
		.popup {
			width: 50vw;
			padding: 2vw;
			h2 {
				font-size: 4.5vw;
				margin-bottom: 2vw;
			}
			.contact-options {
				gap: 1.6vw;
				margin-bottom: 2vw;
				input {
					padding: 1.8vw;
					font-size: 1.8vw;
					border-radius: 0.5vw;
				}
			}
			.submit-button {
				font-size: 1.8vw;
				padding: 1.2vw 2.4vw;
				border-radius: 1.8vw;
			}
			.success-message {
				font-size: 2vw;
				padding: 1.2vw;
				border-radius: 0.5vw;
				.bi-check-circle {
					font-size: 4vw;
				}
				p {
					padding-top: 1.8vw;
					margin-bottom: 1.8vw;
				}
			}
			.close-button {
				font-size: 3.2vw;
				&::before,
				&::after {
					width: 2.2vw;
					height: 0.4vw;
				}
			}
		}
	}

	.options-group {
		min-width: 50vw;
		height: 10vw;
		.option-button {
			font-size: 2.5vw;
		}
		.number-option {
			padding-left: 1.2vw;
		}
	}

	.option-name {
		font-size: 3.4vw;
	}
	.sub-services-title {
		font-size: 2.2vw;
	}
	.additional-options {
		padding-top: 1.2vw;
		p {
			font-size: 2.4vw;
			margin-bottom: 0.8vw;
		}
		ul {
			margin-left: 3.2vw;
		}
		.option-name {
			font-size: 2.4vw;
		}
		.service-option {
			margin-bottom: 1.2vw;
		}
	}

	.cost-name {
		font-size: 2.4vw;
		margin-bottom: 0.8vw;
	}

	.arrow-button {
		margin: 0 2.5vw;
	}
	.service-container h2 {
		font-size: 4.5vw;
		margin-bottom: 4vw;
	}
}

@media screen and (max-width: 768px) {
	.calculate-page {
		padding-top: 6vw;

		h1 {
			font-size: 6vw;
			margin-bottom: 5vw;
		}
		.slide-container {
			height: calc(80vh - 6vw);
		}
		.slide-container2 {
			padding-top: 16vw;
		}
		.paragraph-button {
			font-size: 2.4vw;
			padding: 1.2vw 2.4vw;
			border-radius: 2.6vw;
		}
		.custom-checkbox {
			width: 4vw;
			height: 4vw;
			border-radius: 0.3vw;
			margin-right: 1vw;
		}
		.service-option {
			.option-name {
				&:hover::after {
					content: none;
				}
			}
		}
		.popup {
			width: 60vw;
			padding: 2.5vw;
			h2 {
				font-size: 5.5vw;
				margin-bottom: 2.5vw;
			}
			.contact-options {
				gap: 2vw;
				margin-bottom: 2.5vw;
				input textarea {
					padding: 2.2vw;
					font-size: 2.2vw;
					border-radius: 0.6vw;
					width: 100%;
				}
			}
			.submit-button {
				font-size: 2.2vw;
				padding: 1.4vw 2.8vw;
				border-radius: 2.2vw;
			}
			.success-message {
				font-size: 2.4vw;
				padding: 1.4vw;
				border-radius: 0.6vw;
				.bi-check-circle {
					font-size: 4.7vw;
				}
				p {
					font-size: 2vw;
					padding-top: 2.2vw;
					margin-bottom: 2.2vw;
				}
			}
			.close-button {
				font-size: 3.8vw;
				&::before,
				&::after {
					width: 2.6vw;
					height: 0.5vw;
				}
			}
		}
		.question-mark {
			.bi-question-circle {
				font-size: 2.5vw;
			}

			.tooltip-text {
				display: none;
			}
		}
	}

	.options-group {
		min-width: 60vw;
		height: 12vw;
		padding: 2px;
		.option-button {
			text-align: center;
			font-size: 2.6vw;
		}
		.number-option {
			padding-left: 1.4vw;
		}
	}
	.sub-services-title {
		font-size: 3vw;
	}
	.additional-options {
		padding-top: 2vw;
		p {
			font-size: 2.8vw;
			margin-bottom: 1vw;
		}
		ul {
			margin-left: 3.8vw;
		}
		.option-name {
			font-size: 3.5vw;
		}
		.service-option {
			margin-bottom: 2.2vw;
		}
	}

	.cost-name {
		font-size: 3vw;
		margin-bottom: 1vw;
	}

	.arrow-button {
		margin: 0 3vw;
		transform: translateY(+30%);
		width: 25px;
	}
	// @keyframes cycleToRight {
	// 	0% {
	// 		transform: translate(0, 30%);
	// 	}
	// 	50% {
	// 		transform: translate(1.1vw, 30%);
	// 	}
	// 	100% {
	// 		transform: translate(0, 30%);
	// 	}
	// }
	.arrow-button--next {
		transform: translateY(30%); // Initial positioning for mobile

		// &.animate-arrow {
		// 	animation: cycleToRight 1s infinite; // Apply the combined animation
		// }
	}
	.arrow-button--next:active {
		transform: translateY(+30%) rotate(0deg) scale(0.9);
	}
	.arrow-button--prev:active {
		transform: translateY(+30%) rotate(0deg) scale(0.9);
	}
	.service-container h2 {
		font-size: 5vw;
		margin-bottom: 5vw;
	}
	.cost__container {
		padding-top: 3vw;
	}
}

@media screen and (max-width: 479px) {
	.calculate-page {
		padding-top: 8vw;

		h1 {
			font-size: 7vw;
			margin-bottom: 6vw;
			max-width: 95%;
		}
		.slide-container {
			height: calc(85vh - 8vw);
		}
		.slide-container2 {
			padding-top: 18vw;
		}
		.paragraph-button {
			font-size: 4.5vw;
			padding: 1.5vw 3vw;
			border-radius: 3vw;
		}
		.paragraph-button:hover {
			padding-right: 12px;
		}
		.service-option__container {
			width: 80%;
		}
		.custom-checkbox {
			width: 6vw;
			height: 6vw;
			border-radius: 0.4vw;
			margin-right: 2vw;
		}
		.question-mark {
			.bi-question-circle {
				font-size: 4.5vw;
			}
		}
		.sub-services-title {
			font-size: 6vw;
		}
		.popup {
			transform: translate(-50%, -50%);
			width: 80vw;
			padding: 3vw;
			h2 {
				font-size: 7vw;
				margin-bottom: 3vw;
			}
			.contact-options {
				flex-direction: column;
				gap: 2.5vw;
				margin-bottom: 3vw;
				input {
					padding: 2.6vw;
					font-size: 4vw;
					border-radius: 0.8vw;
				}
			}
			.submit-button {
				font-size: 4vw;
				padding: 1.8vw 3.6vw;
				border-radius: 2.6vw;
			}
			.success-message {
				font-size: 3vw;
				padding: 1.8vw;
				border-radius: 0.8vw;
				margin-top: 8vw;
				.bi-check-circle {
					font-size: 10vw;
				}
				p {
					font-size: 4vw;
					padding-top: 2.6vw;
					margin-bottom: 2.6vw;
				}
			}
			.close-button {
				font-size: 9vw;
				&::before,
				&::after {
					width: 3vw;
					height: 0.6vw;
				}
			}
		}
	}
	.options-group {
		min-width: 90vw;
		height: auto;
		flex-direction: column;
		padding: 1vw;

		.option-button {
			width: 100%;
			font-size: 6.5vw;
			padding: 3vw 0;
			margin-bottom: 1vw;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.number-option {
			padding-left: 0;
		}
	}
	.arrow-button {
		margin: 0 2.5vw;
		transform: translateY(140%);
		width: 25px;
	}
	// @keyframes cycleToRight {
	// 	0% {
	// 		transform: translate(0, 140%);
	// 	}
	// 	50% {
	// 		transform: translate(2vw, 140%);
	// 	}
	// 	100% {
	// 		transform: translate(0, 140%);
	// 	}
	// }
	.arrow-button--next {
		transform: translateY(140%); // Initial positioning for mobile

		// &.animate-arrow {
		// 	animation: cycleToRight 1s infinite; // Apply the combined animation
		// }
	}

	.arrow-button--next:active {
		transform: translateY(140%) rotate(0deg) scale(0.9);
	}
	.arrow-button--prev:active {
		transform: translateY(140%) rotate(0deg) scale(0.9);
	}
	.service-container h2 {
		font-size: 8.7vw;
		margin-bottom: 8vw;
	}
	.main-service-name {
		font-size: 6.1vw !important;
	}
	.option-name {
		font-size: 5.5vw;
		width: max-content;
	}
	.cost__container {
		width: 95%;
		padding-top: 4vw;
	}
	.cost-name {
		font-size: 4.2vw;
		margin-bottom: 2vw;
	}
	.additional-options {
		padding-top: 2vw;
		p {
			font-size: 4vw;
		}
		.option-name {
			width: auto;
			font-size: 4vw;
		}
	}
	.service-option__container {
		width: 85%;
	}
}
