.hero-section {
	display: flex;
	flex-direction: column;
	height: 100vh; // Ensure it takes full viewport height
	background-color: var(--color-black);
	padding-top: 82px;
}

.nav-heading {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 25px;

	h1 {
		font-size: 82px;
		font-family: "GraphikLCG-Semibold", sans-serif;
		color: var(--color-white);
	}

	p {
		font-size: 24px;
		color: var(--color-white);
	}
}

.work-with {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start; // Changed from center to align at the top
	gap: 4rem;
}

.nav-sd-heading {
	margin-top: 40px; // Adjusted from 120px to fit better in the 0.4fr space
}

.nav-sd-heading h2 {
	color: var(--color-white);
}

.ticker-container {
	width: 100%;
}

// Extra small devices (small phones, 320px and down)
@media only screen and (max-width: 320px) {
	.nav-heading {
		p {
			font-size: 16px !important;
			font-family: "GraphikLCG-Medium", sans-serif;
		}
	}
	.nav-sd-heading h2 {
		font-size: 22px !important;
	}
	.work-with {
		gap: 1.5rem !important;
	}
}

// Small devices (phones, 576px and down)
@media only screen and (max-width: 576px) {

	.nav-heading {
		h1 {
			font-size: 62px;
		}
		p {
			font-size: 18px;
			font-family: "GraphikLCG-Medium", sans-serif;
		}
	}
	.nav-sd-heading h2 {
		font-size: 26px;
	}
	.work-with {
		gap: 3rem;
		margin-top: -1rem;
	}
	.nav-sd-heading {
		margin-top: 20px;
	}

}

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) {
	.nav-heading {
		padding-top: 22px;
		h1 {
			font-size: 64px;
		}
		p {
			font-size: 20px;
		}
	}
	.nav-sd-heading h2 {
		font-size: 28px;
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (min-width: 992px) {
	.nav-heading {
		padding-top: 22px;
		h1 {
			font-size: 72px;
		}
		p {
			font-size: 22px;
		}
	}
	.nav-sd-heading h2 {
		font-size: 32px;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
	.nav-heading {
		h1 {
			font-size: 82px;
		}
		p {
			font-size: 24px;
		}
	}
	.nav-sd-heading h2 {
		font-size: 36px;
	}
}

// Full HD (1920px and up)
@media only screen and (min-width: 1920px) {
	.nav-heading {
		padding-top: 42px;
		h1 {
			font-size: 102px;
		}
		p {
			font-size: 28px;
		}
	}
	.nav-sd-heading h2 {
		font-size: 38px;
	}
}

// 2K (2560px to 3839px)
@media only screen and (min-width: 2560px) and (max-width: 3839px) {
	.hero-section {
	  padding-top: 120px; // Scale down from 164px in 4K
	}
  
	.nav-heading {
	  gap: 40px; // Scale down from 50px in 4K
	  h1 {
		font-size: 128px; // Scale down from 172px in 4K
	  }
	  p {
		font-size: 36px; // Scale down from 48px in 4K
	  }
	}
  
	.work-with {
	  gap: 6rem; // Scale down from 7.4rem in 4K
	}
  
	.nav-sd-heading {
	  margin-top: 60px; // Scale down from 80px in 4K
	  h2 {
		font-size: 54px; // Scale down from 72px in 4K
	  }
	}
  }

// 4K (3840px and up)
@media only screen and (min-width: 3840px) {
	.hero-section {
		padding-top: 164px;
	}
	.nav-heading {
		gap: 50px;
		h1 {
			font-size: 172px;
		}
		p {
			font-size: 48px;
		}
	}
	.work-with {
		gap: 7.4rem;
	}
	.nav-sd-heading {
		margin-top: 80px;
		h2 {
			font-size: 72px;
		}
	}
}
