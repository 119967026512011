.activity {
	background-color: var(--color-black);
	display: flex;
	flex-direction: column;
	align-items: center;
	// padding: 50px 0;
	padding-bottom: 80px;
	width: 100%;
}

.activity-heading {
	text-align: center;
	margin-bottom: 50px;
}

.heading-text {
	color: var(--color-white);
	h1 {
		color: var(--color-white);
		margin-bottom: 15px;
	}
}

.activity-text {
	font-family: "Graphik LCG Regular", sans-serif;
	font-weight: 300;
	font-size: 24px;
	letter-spacing: -0.1px;
	line-height: 140%;
	text-align: center;
	max-width: 800px;
	margin: 0 auto;
}

.cards-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 24px;
	width: 100%;
	max-width: 1200px;
	padding: 0 32px;
}

.container-card {
	background-color: var(--color-white);
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 32px;
	width: calc(33.33% - 16px);
	max-height: 386px;
	width: 379px;
	transition: transform 0.2s ease;
	&:hover {
		transform: scale(1.05);
	}
}

.card-logo {
	margin-bottom: 15px;
	.logo-container {
		width: 90px;
		height: 90px;
		background-color: var(--color-black);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 24px;
	}

	img {
		width: 75px;
		height: 75px;
		object-fit: contain;
	}
}

.card-text {
	text-align: start;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;

	h3 {
		color: var(--color-black-2nd);
		font-size: 24px;
	}

	p {
		font-family: "Manrope", sans-serif;
		font-size: 18px;
		font-weight: 500;
		color: var(--color-gray);
	}
}

// 4K Resolution (3840x2160)
@media screen and (min-width: 3840px) {
	.activity {
		padding: 2.9167vw;
	}

	.activity-heading {
		margin-bottom: 3vw;
	}

	.heading-text h1 {
		font-size: 102px; // Approximately 192px on 4K
	}

	.activity-text {
		font-size: 64px; // Approximately 96px on 4K
		letter-spacing: 0.5px;
		max-width: 60vw; // Approximately 2304px on 4K
	}

	.cards-container {
		max-width: 90vw; // Approximately 3456px on 4K
		gap: 2vw; // Approximately 76px on 4K
	}
	.card-logo {
		margin-bottom: 40px;
	}
	.container-card {
		width: calc(33.33% - 1.33vw); // Adjust for gap
		padding: 3vw; // Approximately 115px on 4K
		max-height: none;
		height: auto;
		display: flex;
		flex-direction: column;
		border-radius: 48px;
	}

	.card-logo .logo-container {
		width: 7vw; // Approximately 384px on 4K
		height: 7vw; // Approximately 384px on 4K
		border-radius: 48px;
	}

	.card-logo img {
		width: 6vw; // Approximately 307px on 4K
		height: 6vw; // Approximately 307px on 4K
	}

	.card-text {
		h3 {
			font-size: 1.875vw; // Approximately 72px on 4K
			margin-bottom: 0.5vw; // Add some space between title and paragraph
		}

		p {
			font-size: 1.354vw; // Approximately 52px on 4K
			line-height: 1.4;
		}
	}
}

// 2K Resolution (2560x1440)
@media screen and (min-width: 2560px) and (max-width: 3839px) {
	.activity {
	  padding: 2.5vw 0;
	}
  
	.activity-heading {
	  margin-bottom: 2vw;
	}
  
	.heading-text h1 {
	  font-size: 68px; // Scaled down from 102px in 4K
	}
  
	.activity-text {
	  font-size: 42px; // Scaled down from 64px in 4K
	  letter-spacing: 0.3px;
	  max-width: 60vw; // Approximately 1536px on 2K
	}
  
	.cards-container {
	  max-width: 90vw; // Approximately 2304px on 2K
	  gap: 1.5vw; // Approximately 38px on 2K
	}
  
	.card-logo {
	  margin-bottom: 30px;
	}
  
	.container-card {
	  width: calc(33.33% - 1vw); // Adjust for gap
	  padding: 2.5vw; // Approximately 64px on 2K
	  max-height: none;
	  height: auto;
	  display: flex;
	  flex-direction: column;
	  border-radius: 36px;
	}
  
	.card-logo .logo-container {
	  width: 7vw; // Approximately 205px on 2K
	  height: 7vw; // Approximately 205px on 2K
	  border-radius: 36px;
	}
  
	.card-logo img {
	  width: 6vw; // Approximately 179px on 2K
	  height: 6vw; // Approximately 179px on 2K
	}
  
	.card-text {
	  h3 {
		font-size: 2vw; // Approximately 51px on 2K
		margin-bottom: 0.5vw;
	  }
  
	  p {
		font-size: 1.4vw; // Approximately 36px on 2K
		line-height: 1.4;
	  }
	}
  }

@media (max-width: 1199px) {
	.container-card {
		width: calc(50% - 12px);
		max-width: none;
		height: auto;
		max-height: 450px;
	}

	.card-text {
		gap: 10;
	}
}

@media (max-width: 899px) {
	.container-card {
		width: 100%;
		max-width: 540px;
		max-height: 490px;
		padding: 16px;
	}

	.card-text p {
		font-size: 18px;
	}
}

@media screen and (max-width: 768px) {
	.container-card {
		padding: 32px;
	}

	.cards-container {
		flex-direction: column;
		align-items: center;
	}
	.card-text {
		align-items: center;
	}
	.activity{
		padding-top: 10px;
	}
}

@media (max-width: 479px) {
	.heading-text {
		padding: 0 16px;
		h1 {
			font-size: 40px;
		}
	}

	// .activity {
	// 	padding-top: -30%;
	// }

	.cards-container {
		padding: 0 16px;
	}

	.container-card {
		padding: 24px;
		height: auto;
		min-height: 300px;
	}

	.activity-text {
		font-size: 20px;
	}
	.card-logo {
		margin-bottom: 5px;
	}
	.card-logo .logo-container {
		width: 80px;
		height: 80px;
	}

	.card-logo img {
		width: 65px;
		height: 65px;
	}

	.card-text h3 {
		font-size: 28px;
	}

	.card-text p {
		font-size: 18px;
	}
}

@media (max-width: 320px) {
	.activity-text {
		font-size: 16px;
	}
}
