// .contact-page {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	min-height: 100vh;
//   }
  
  .contact__container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10rem 0 10rem 0;
  }
  
  .container__social.button-group {
	position: relative;
	z-index: 2;
	display: flex;
	min-width: 600px;
	height: 100px;
	background-color: #000;
	border-radius: 7px;
	padding: 4px;
  
	& > div {
	  cursor: default;
	  flex: 1;
	  background-image: linear-gradient(to top, #242424 0%, #303030 100%);
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  margin: 1px;
	  transition: 0.2s;
	  font-size: 2.5em;
	  box-shadow: inset 0 20px 4px -21px rgba(#fff, 0.4), 0 19px 13px 0 rgba(0,0,0,0.3);
	  color: #181818;
	  position: relative;
	  z-index: 2;
  
	  &:before {
		content: "";
		display: block;
		width: 0.8em;
		height: 0.8em;
		transition: 0.1s;
		background-image: radial-gradient(circle 30px at center, #76cedb 0%, #76cedb 47%, #76cedb 100%);
		position: absolute;
		filter: blur(15px);
		top: 50%;
		left: 50%;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
	  }
  
	  &:after {
		content: "";
		display: block;
		height: 70px;
		width: 1px;
		position: absolute;
		border-radius: 50%;
		z-index: -1;
		opacity: 0;
		transition: 0.2s;
		filter: blur(0px);
		box-shadow:
		  -75px 0 0px 0px rgba(#b3e1ff, 0.3),
		  74px 0 0px 0px rgba(#b3e1ff, 0.35);
	  }
  
	  svg {
		height: 2em;
		width: 2em;
		position: relative;
		display: block;
		fill: #181818;
		filter: drop-shadow(0 1px 1px rgba(#fff, 0.15)) url(#inset-shadow);
	  }
  
	  &.active {
		background-image: linear-gradient(to top, #151515 0%, #1d1d1d 100%);
		box-shadow: inset 0 16px 14px -21px transparent, 0 0px 13px 0 rgba(0,0,0,0.3), inset 0 0 7px 2px rgba(0,0,0,0.4);
		z-index: 0;
  
		&:before {
		  width: 1em;
		  height: 1em;
		  opacity: 0.8;
		}
  
		&:after {
		  opacity: 0;
		}
  
		svg {
		  fill: url(#active);
		  filter: drop-shadow(0 1px 1px rgba(#fff, 0));
		}
	  }
	}
  
	div:first-of-type {
	  border-radius: 4px 0 0 4px;
	  &:after {
		box-shadow: -85px 0 18px 1px transparent, 83px 0 12px 1px #b3e1ff;
	  }
	}
  
	div:last-of-type {
	  border-radius: 0 4px 4px 0;
	  &:after {
		box-shadow: -85px 0 18px 1px #b3e1ff, 83px 0 12px 1px transparent;
	  }
	}
  }
  
  .svg-filters {
	height: 0;
	width: 0;
  }

  .button-group {
	-webkit-tap-highlight-color: transparent;
	user-select: none;
  }


  @media only screen and (max-width: 576px) {
	.container__social.button-group {
		min-width: 300px !important;
	
		& > div {
		  font-size: 4em !important;
		}
	  }
  }


  // mobile
  @media only screen and (max-width: 768px) {

	.container__social.button-group {
	  min-width: 350px;
	  height: 100px;
	  flex-direction: row;
  
	  & > div {
		font-size: 3em;
  
		svg {
		  height: 2em;
		  width: 2em;
		}
	  }
	}
  }
  
  // 2K Resolution (2560px to 3839px)
  @media only screen and (min-width: 2560px) and (max-width: 3839px) {
	.container__social.button-group {
	  min-width: 800px;
	  height: 120px;
  
	  & > div {
		font-size: 3em;
  
		svg {
		  height: 2em;
		  width: 2em;
		}
	  }
	}
  }
  
  // 4K Resolution (3840px and up)
  @media only screen and (min-width: 3840px) {
	.container__social.button-group {
	  min-width: 1200px;
	  height: 180px;
  
	  & > div {
		font-size: 4em;
  
		svg {
		  height: 2em;
		  width: 2em;
		}
	  }
	}
  }