.ticker2-container {
	width: 100%;
	overflow: hidden;
  }
  
  .ticker2-fade-container {
	position: relative;
	width: 100%;
	height: 350px;
  }
  
  .ticker2-fade-container::before,
  .ticker2-fade-container::after {
	content: "";
	position: absolute;
	top: 0;
	width: 100px;
	height: 100%;
	z-index: 2;
  }
  
  .ticker2-fade-container::before {
	left: 0;
	// background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
  }
  
  .ticker2-fade-container::after {
	right: 0;
	// background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
  }
  
  .ticker2-content {
	display: flex;
	height: 100%;
  }
  
  .ticker2-item {
	flex: 0 0 auto;
	width: 500px;
	height: 350px; 
	padding: 0 5px;
  }
  
  .ticker2-item img {
	width: 100%;
	height: 100%;
	object-fit: cover; 
	border-radius: 5px; 
  }
  