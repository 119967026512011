@import "../sass/abstracts/mixins";

.language-selector {
	select {
		appearance: none;
		background-color: var(--color-main-white);
		border: 1px solid var(--color-new-black);
		color: var(--color-new-black);
		padding: 5px 24px 5px 10px;
		font-size: 16px;
		border-radius: 2px;
		cursor: pointer;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><path d="M0 0h12L6 6z" fill="%23000000"/></svg>');
		background-repeat: no-repeat;
		background-position: right 8px center;

		&:focus {
			outline: none;
		}
		option {
			background-color: var(--color-white);
			color: var(--color-black);
		}
	}
}

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	background-color: var(--color-black);
	transition: transform 0.3s ease-in-out;

	&.mobile {
		flex-direction: column;
	}
}

.navbar-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 4.8rem;
	height: 82px;
}

.logo {
	display: flex;
	width: 173px;
	height: 50px;
	border-radius: inherit;
	object-position: center center;
	object-fit: cover;
	image-rendering: auto;

	.logo-link {
		display: inline-block;
		text-decoration: none;
		line-height: 0;
	}
}

.logo img {
	width: 100%;
	height: auto;
	object-fit: contain;
	@media screen and (max-width: 768px) {
		width: 50px;
	}
}

.nav-menu {
	font-family: "GraphikLCG-Medium";
	font-style: normal;
	display: flex;
	gap: 1rem;
	font-size: 20px;
	letter-spacing: -0.1px;
	line-height: 140%;
}

.nav-menu li {
	padding: 12px;
	cursor: pointer;
}

.hamburger {
	display: none;
	cursor: pointer;
}

.nav-menu-mobile {
	display: none;
	width: 100%;
	// padding: 0 0 40px;
}

@media only screen and (max-width: 375px) {
	.navbar {
		transform: none !important;
	}
}

@media only screen and (max-width: 576px) {
	.nav-menu {
		a {
			font-size: 24px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.nav-menu-mobile {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--color-white);
		width: 100%;
		max-height: 0;
		padding: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
		border-radius: 2px;

		&.open {
			max-height: 600px; // Adjust based on your content
			padding: 15px;
		}
	}

	.nav-menu-mobile ul {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		width: 100%;
	}

	.nav-menu-mobile li {
		font-size: 24px;
		width: 100%;
		text-align: center;
	}

	.nav-menu {
		display: none;
	}

	.hamburger {
		display: block;
	}

	.navbar-content {
		justify-content: space-between;
		padding: 0 24px;
	}
	.mobile-controls {
		display: flex;
		align-items: center;
	}

	.language-selector.mobile {
		margin-right: 15px;
	}
	.menu-mobile li a {
		color: var(--color-black) !important;
	}
	.menu-mobile li a:hover {
		color: var(--color-blue-light) !important;
	}
	.language-selector {
		select {
			appearance: none;
			background-color: var(--color-main-white);
			border: 1px solid var(--color-new-black);
			color: var(--color-new-black);
			padding: 5px 24px 5px 10px;
			font-size: 16px;
			border-radius: 2px;
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><path d="M0 0h12L6 6z" fill="%23000000"/></svg>');
			background-repeat: no-repeat;
			background-position: right 8px center;
		}
	}
}
// 2K (2560px to 3839px)
@media only screen and (min-width: 2560px) and (max-width: 3838px) {
	.navbar-content {
		padding: 0 7.2rem;
		height: 123px;
	}

	.logo {
		width: 260px;
		height: 75px;
	}

	.nav-menu {
		font-size: 30px;
		gap: 1.5rem;

		li {
			padding: 18px;
		}

		a {
			font-size: 32px;
		}
	}

	.ticker-item {
		height: 180px;
	}

	.ticker-item img {
		max-width: 240px;
		max-height: 170px;
	}
	.language-selector {
		select {
			font-size: 24px;
			option {
				font-size: 14px;
			}
		}
	}
}

// 4K (3840px and up)
@media only screen and (min-width: 3839px) {
	.navbar-content {
		padding: 0 9.6rem;
		height: 164px;
	}

	.logo {
		width: 346px;
		height: 100px;
	}

	.nav-menu {
		font-size: 40px;
		gap: 2rem;

		li {
			padding: 24px;
		}

		a {
			font-size: 42px;
		}
	}
	.ticker-item {
		height: 300px;
	}

	.ticker-item img {
		max-width: 400px;
		max-height: 280px;
	}
	.language-selector {
		select {
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M0 0h12L6 6z" fill="%23000000"/></svg>');
			background-size: 0.45em 0.25em;
			font-size: 36px;
			option {
				font-size: 16px;
			}
		}
	}
}
