.footer {
	position: sticky;
	opacity: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;
	height: 100%;
	background-color: var(--color-black);
	color: var(--color-white);
	pointer-events: auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 4.8rem;

	&.visible {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(30%);
		transition: transform 2s ease-in-out;
		@media (max-width: 768px) {
			transform: translateY(0);
		}
	}

	.footer__container {
		max-width: 100%;
		margin: 0 auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.footer__content {
		padding-top: 7rem;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.footer__logo {
			width: 50%;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 70%;
			}
		}

		.footer__next-text {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: center;
			align-items: center; // Center content
			gap: 5rem;

			> div {
				max-width: 80%;
			}

			.footer__copy {
				font-size: 36px;
				font-weight: bold;
				line-height: 1.3;
				margin-bottom: 20px;
			}

			.footer__copy-en {
				color: #afafaf;
				font-size: 16px;
				font-variation-settings: "wght" 600;
				letter-spacing: -0.03em;
				line-height: 1.3;
				margin: 0;
			}
		}

		.footer__next-area {
			width: max-content;
			align-self: flex-end;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			position: relative;
			overflow: visible;
			max-height: none;

			.footer__next-link {
				display: block;
				font-size: 42px;
				font-variation-settings: "wght" 500;
				letter-spacing: -0.06em;
				line-height: 0.9;
				position: static;
				margin-bottom: 15px;
				transition: opacity 0.3s ease;
				text-decoration: none;

				&:hover {
					color: var(--color-white);
					cursor: default;
				}

				+ .link.-end {
					position: absolute;
					bottom: 0;
					right: 0;
					opacity: 0;
					pointer-events: none;
					transition: opacity 0.2s ease, transform 0.2s ease;
					transform: translateY(0);
				}
			}

			&:hover {
				.link.-end {
					opacity: 1;
					pointer-events: auto;
					transform: translateY(20px);
				}

				.footer__next-link {
					text-decoration: underline;
				}
			}
		}

		.link.-end {
			display: flex;
			align-items: center;
			color: #fff;
			text-decoration: none;
			transition: opacity 0.2s ease;

			&:hover {
				opacity: 0.7;
			}

			.link__text {
				margin-right: 10px;

				.en {
					font-size: 16px;
					font-weight: bold;
				}

				small {
					font-size: 12px;
					margin-left: 5px;
					opacity: 0.7;
				}
			}

			.icon-button-arrow {
				position: relative;
				width: 24px;
				height: 24px;

				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					i {
						display: block;
						width: 12px;
						height: 1px;
						background-color: #fff;
						position: absolute;
						transition: transform 0.3s ease;

						&:first-child {
							transform: rotate(45deg) translateY(-5px);
						}

						&:last-child {
							transform: rotate(-45deg) translateY(5px);
						}
					}
				}
			}
		}

		.footer__menu {
			width: 100%;
			margin-left: auto;
			display: flex;
			justify-content: flex-end;

			.footer__menu-column {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: flex-end;
				flex: 1;
				// margin-bottom: 30px;

				h3 {
					font-size: 28px;
					margin-bottom: 20px;
					opacity: 0.7;
				}

				ul {
					list-style: none;
					padding: 0;

					li {
						display: flex;
						justify-content: flex-end;
						margin-bottom: 15px;

						a {
							font-size: 24px;
						}
					}
				}
			}
		}

		.footer__copy {
			text-align: start;

			p {
				margin: 0;
				font-size: 12px;
				opacity: 0.7;
			}
		}
	}

	.content-container {
		display: flex;
		gap: 5rem;
	}

	.footer__menu-container {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: flex-end;
		width: 50%;
		// padding-bottom: 4rem;
	}

	.footer-line {
		border-bottom: 1px;
		border-color: var(--color-white);
		border-style: dashed;
		border-top: 1px;
		background-color: rgb(255, 255, 255);
		opacity: 1;
	}

	.footer-line__text {
		color: #afafaf !important;
		font-size: 14px !important;
		margin-bottom: 0 !important;
		padding-bottom: 40px;
	}

	.footer__line-container {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
}

@media (max-width: 375px) {
	.footer {
		position: relative;
		height: 100%;
	}
}

@media (max-width: 576px) {
	.footer {
		padding: 20px;
	}
	.content-container {
		flex-direction: inherit;
		gap: 6rem !important;
	}
	.footer__content {
		padding-top: 0 !important;
	}
	.footer__next-text {
		gap: 6rem !important;
	}
	.footer__copy-en {
		font-size: 16px !important;
	}
	.footer__copy {
		margin-bottom: 10px !important;
		font-size: 26px !important;
	}
	.footer__next-area {
		gap: 0 !important;
	}
	.footer__menu-column ul li a {
		font-size: 18px !important;
	}
	.footer__menu-column h3 {
		margin-bottom: 10px !important;
		font-size: 22px !important;
	}
	.footer-line__text {
		padding-bottom: 10px !important;
	}
	.footer__logo {
		width: 100% !important;
	}
	.link__text {
		margin-right: 0 !important;
	}
}

@media (max-width: 768px) {
	.footer {
		position: relative;
		opacity: 1 !important;
		visibility: visible !important;
		.footer__next-link {
			font-size: 32px !important;
			margin-bottom: 0px !important;
		}
		.footer__container {
			padding: 20px 0 30px;
		}
		.footer__logo img {
			max-width: 60%;
		}
		.content-container {
			gap: 3rem;
		}
		.footer__content {
			padding-top: 0;
			.footer__logo {
				width: 60%;
				// margin-bottom: 30px;
			}
			.footer__next-text {
				width: 100%;
				padding: 0;
				align-items: flex-start;

				> div {
					max-width: 100%;
				}

				.footer__copy,
				.footer__copy-en {
					font-size: 28px;
				}
				.en {
					font-size: 20px;
				}
			}

			.footer__next-area {
				width: 100%;
				align-self: auto;
				align-items: flex-start;
				gap: 0;
			}
			.link.-end {
				position: relative !important;
			}
			.footer__menu {
				width: 100%;
				margin-left: 0;
				display: flex;
				justify-content: flex-end;
				padding-top: 4rem;

				.footer__menu-container {
					flex-direction: column;
				}
			}
		}

		.footer__info {
			flex-direction: column;
			align-items: flex-start;

			.footer__logo {
				margin-bottom: 15px;
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	.footer .footer__content .footer__next-text .footer__copy {
		font-size: 3.1vw;
	}
	.footer .footer__content .footer__next-text .footer__copy-en {
		font-size: 1.7vw;
	}
	.footer .footer__content .footer__menu {
		margin-top: -1.5vw;
	}
	.footer .footer__content .footer__next-area .footer__next-link {
		font-size: 2.6vw;
		margin-bottom: 1vw;
	}
	.footer .footer__content .footer__menu .footer__menu-column h3 {
		font-size: 1.7vw;
	}
	.footer .footer__content .footer__menu .footer__menu-column ul li a {
		font-size: 1.65vw;
	}
	.footer .footer__content .link.-end .link__text .en {
		font-size: 1vw;
		font-family: "Graphik LCG Regular", sans-serif;
	}
}

@media screen and (min-width: 2560px) {
	.footer .footer-line__text {
		font-size: 0.729vw !important;
	}
}
