// @mixin style-link($color) {
//     display: inline-block;
//     text-transform: uppercase;
//     text-decoration: none;
//     color: $color;
// }

// @mixin clearfix {
//     &::after {
//         content: "";
//         display: table;
//         clear: both;
//     }
// }

// @mixin phone {
//     @media (max-width: 600px) {
//         @content;
//     }
// }

// // /* В аругумент миксина мы будем указывать несколько вариантов брейкпоинтов, таких как:

// //     - phone
// //     - tab-port
// //     - tab-land
// //     - big-screen

// */

//миксин для адаптива
@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 600px) {
			// говорит чтобы не применялись медиаправила например для печати
			@content;
		}
	}

	@if $breakpoint == tab-port {
		@media only screen and (max-width: 900px) {
			@content;
		}
	}

	@if $breakpoint == tab-land {
		@media only screen and (max-width: 1240px) {
			@content;
		}
	}

	@if $breakpoint == big-laptop {
		@media only screen and (max-width: 1800px) {
			@content;
		}
	}

	@if $breakpoint == big-screen {
		@media only screen and (min-width: 1800px) {
			@content;
		}
	}
}
