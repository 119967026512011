.slide-in {
  transition: all ease-out;
  opacity: 0;
  transform: translateY(0) translateX(0);
  width: 100%;
}

.slide-in.visible {
  opacity: 1;
}

/* For sliding up */
.slide-in-up:not(.visible) {
  transform: translateY(30px);
}

.slide-in-up.visible {
  transform: translateY(0);
}

/* For sliding right */
.slide-in-right:not(.visible) {
  transform: translateX(-30px);
}

.slide-in-right.visible {
  transform: translateX(0);
}

/* For sliding down */
.slide-in-down:not(.visible) {
  transform: translateY(-30px);
}

.slide-in-down.visible {
  transform: translateY(0);
}

/* For sliding left */
.slide-in-left:not(.visible) {
  transform: translateX(30px);
}

.slide-in-left.visible {
  transform: translateX(0);
}