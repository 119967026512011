@keyframes floatAnimation {
	0% {
	  transform: translateY(0);
	  opacity: 1;
	}
	25% {
	  opacity: 1;
	}
	50% {
	  transform: translateY(-25px);
	  opacity: 1;
	}
	75% {
	  opacity: 1;
	}
	100% {
	  transform: translateY(0);
	  opacity: 1;
	}
  }
  
  .animated-image {
	animation: floatAnimation 4s ease-in-out infinite;
  }