.Ticker {
    overflow: hidden;
    width: 100%;
    max-width: 680px;
    height: 90px;
    position: relative;
    margin: 0 auto;
    background: #272829;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 25%;
        z-index: 1;
        pointer-events: none;
    }

    &::before {
        left: -1px;
        background: linear-gradient(to right, #272829, rgba(39, 40, 41, 0));
        z-index: 5;
    }

    &::after {
        right: -1px;
        background: linear-gradient(to left, #272829, rgba(39, 40, 41, 0));
        z-index: 5;
    }
}

.ticker-image {
    max-width: 180px;
    max-height: 80px;
    object-fit: cover;
    padding: 0 18px;
    box-sizing: border-box;
}

@media only screen and (max-width: 576px) {
    .Ticker {
        height: 120px;
        max-width: 100%;
    }
    .ticker-image {
        max-width: 180px;
        max-height: 110px;
    }
}

@media only screen and (min-width: 1920px) {
    .Ticker {
        height: 140px;
        max-width: 850px;
    }
    .ticker-image {
        max-width: 180px;
        max-height: 130px;
    }
}

@media only screen and (min-width: 3840px) {
    .Ticker {
        height: 280px;
        max-width: 1700px;
    }
    .ticker-image {
        max-width: 360px;
        max-height: 260px;
    }
}
