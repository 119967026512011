@import "../abstracts/mixins";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons";
*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

:root {
	// Основные цвета сайта
	--color-blue-light: #76cedb;

	// Черный и белый цвет сайта
	--color-black: #272829;
	--color-black-2nd: #050505;
	--color-white: #eeeeee;
	--color-gray: #737373;
	--color-new-black: #000000;

	--color-main-white: #ffffff;
}
button {
	appearance: none;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;
	outline: none;
}

html {
	font-size: 62.5%;
	height: 100%;
	scroll-behavior: smooth;

	@include respond(big-laptop) {
		font-size: 65%;
	}

	@include respond(tab-land) {
		font-size: 56.25%;
	}

	@include respond(tab-port) {
		font-size: 50%;
	}

	@include respond(phone) {
		font-size: 30%;
	}

	@include respond(big-screen) {
		font-size: 75%;
	}
}

@font-face {
	font-family: "GraphikLCG-Bold";
	src: url("../../fonts/GraphikLCG-Bold.woff") format("woff"),
		url("../../fonts/GraphikLCG-Bold.woff2") format("woff2"),
		url("../../fonts/GraphikLCG-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GraphikLCG-Semibold";
	src: url("../../fonts/GraphikLCG-Semibold.woff2") format("woff2"),
		url("../../fonts/GraphikLCG-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "GraphikLCG-Medium";
	src: url("../../fonts/GraphikLCG-Medium.woff2") format("woff2"),
		url("../../fonts/GraphikLCG-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GraphikLCG-Regular";
	src: url("../../fonts/GraphikLCG-Regular.woff") format("woff"),
		url("../../fonts/GraphikLCG-Regular.woff2") format("woff2"),
		url("../../fonts/GraphikLCG-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

body {
	width: 100%;
	overflow-x: hidden;
	background-color: var(--color-black);
	font-family: "GraphikLCG-Medium", sans-serif;
	overflow-x: hidden;
	overflow-y: scroll;
}

.smooth-scroll-wrapper {
	z-index: 3000;
	overflow: hidden;
	width: 100%;
}
//----------Animation main page-------------
.animated-section {
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animated-section.is-visible {
	opacity: 1;
	transform: translateY(0);
}
//----------Animation main page-------------
ul {
	list-style-type: none;
}

a {
	text-decoration: none;
	color: var(--color-white);
}

a:hover {
	color: var(--color-blue-light);
	text-decoration: underline;
}

h1 {
	font-family: "Graphik LCG Regular", sans-serif;
	font-size: 52px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.5px;
	line-height: 110%;
	text-align: center;
	text-decoration: none;
	text-transform: none;

	color: var(--color-black);
}

h2 {
	font-family: "Graphik LCG Regular", sans-serif;
	font-size: 36px;
}

h3 {
	font-size: 24px;
	font-family: "Graphik LCG Medium", sans-serif;
	font-weight: 400;
}

p {
	font-size: 16px;
	font-family: "GraphikLCG-Regular", sans-serif;
	letter-spacing: -0.5px;
	line-height: 140%;
	margin-bottom: 18px;
}
.p14 {
	font-size: 14px;
}

.p12 {
	font-size: 12px;
}

.App {
	height: 100%;
}
