@import "abstracts/mixins";
@import "abstracts/functions";

@import "base/animation";
@import "base/typography";
@import "base/utilities";
@import "base/index.scss";

@import "../components/NavbarStyle.scss";

// .app {
// 	display: grid;
// 	grid-template-rows: 1fr auto; // 1fr for hero, auto for the rest
// 	min-height: 100vh; // Ensure the container takes at least the full viewport height
//   }

.App {
	position: relative;
	z-index: 10;
	min-height: 100vh;
}

.main-content {
	position: relative;
	z-index: 10;
	min-height: 100vh;
	width: 100%;
	transition: transform 0.3s ease-out;
	overflow: hidden;

}

@media screen and (max-width: 375px) {
	.main-content {
		transform: none !important;
	}
}
