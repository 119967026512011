.body {
	overflow: hidden;
}
.history {
	background-color: var(--color-main-white);
}

.history-section {
	padding-top: 50px;
	width: 100%;
	height: 105vh;
	overflow: hidden;
}

.history-heading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.history-heading h2 {
	font-family: "Graphik LCG Regular", sans-serif;
	font-size: 52px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -2px;
	line-height: 110%;
	text-align: center;
	text-decoration: none;
	text-transform: none;

	color: var(--color-black);
}

.history-calendar {
	padding: 0 100px;
	max-width: 100%; // Adjust this value as needed
}

.calendar-years {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-top: 15px;
}

.years {
	display: flex;
	flex-direction: row;
	gap: 20px;

	li {
		font-family: "GraphikLCG-Medium", sans-serif;
		font-size: 20px;
		color: var(--color-black);
		position: relative;
		cursor: pointer;
		padding-bottom: 5px; // Adjust this to control space between text and underline

		&:hover {
			color: var(--color-blue-light);
		}

		&.active {
			color: var(--color-black);

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 2px; // Adjust thickness of underline
				background-color: var(--color-black);
			}
		}
	}
}

.calendar-events {
	max-width: 1260px;
	.events {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 7px;
		list-style-type: none;
		padding: 0;
		margin: 0;
		flex-wrap: wrap;

		li {
			padding: 10px 0;
		}

		.button-events {
			display: flex;
			width: 100px;
			height: 36px;
			padding: 0 16px;
			justify-content: center;
			align-items: center;
			background: transparent;
			border: 3px solid var(--color-black);
			cursor: pointer;
			font-family: "GraphikLCG-Medium", sans-serif;
			border-radius: 10px;
			opacity: 1;
			color: var(--color-black);
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				color: var(--color-blue-light);
			}

			&:active,
			&.active {
				color: var(--color-white);
				background: var(--color-black);
			}
		}
	}
}

.calendar-description {
	display: flex;
	gap: 100px;
	width: 100%;
	padding: 0 100px;
	margin: 0 auto;
	margin-top: 10px;
	align-items: center;
	justify-content: center;
	.description-image {
		width: 300px;
		height: 300px;
		top: 0;
		left: 0;
		overflow: hidden;
		border-radius: 32px;
		transition: all 300ms cubic-bezier(0.46, 0, 0.29, 1.005);

		img {
			width: 100%;
			height: 100%;
			object-fit: contain; // This will make sure the image covers the area without distortion
			object-position: center; // This centers the image within the container
		}
	}
}

.image-description {
	position: relative;
}

.article-description {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40%;
}

.description-inf {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	gap: 5px;
}

.description-text {
	width: 100%;
	height: auto;
}

.description-text-link {
	font-family: "GraphikLCG-Medium", sans-serif;
	font-size: 34px;
	color: var(--color-black-2nd);
	margin: 0;
	cursor: default;
}

.description-text-p {
	margin-top: 10px;
	color: var(--color-black);
}

.inf-element {
	display: flex;
	align-items: center;
	justify-content: center;

	.icon-container {
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1px;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			fill: var(--color-gray);
		}

		.icon-container svg.inf-logo3 {
			stroke: var(--color-gray);
		}
	}

	.inf-text {
		font-size: 12px;
		line-height: 16px;
		color: var(--color-black);
	}
}

.slide-enter {
	transform: translateX(-100px);
	opacity: 0;
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-enter-active {
	transform: translateX(0);
	opacity: 1;
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-exit {
	transform: translateX(0);
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	opacity: 0;
	display: none;
}

.slide-exit-active {
	transform: translateX(100px);
	opacity: 0;
	display: none;
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-enter-active,
.slide-exit-active {
	transform: translateY(0);
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.small-font {
	font-size: 13px !important;
}

@media screen and (max-width: 576px) {
	.history-section {
		padding: 30px 0;
	}
	.history-heading {
		h2 {
			font-size: 42px;
		}
	}
	.history-calendar {
		padding: 0 20px !important;
	}

	.calendar-events {
		width: min-content;
		display: none;
		.events {
			flex-direction: column;
			align-items: stretch;

			li {
				width: 100%;
			}

			.button-events {
				width: 100%;
			}
		}
	}

	.calendar-years {
		padding-top: 20px;
	}

	.calendar-description {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		gap: 25px !important;
		position: relative;
		width: 100%;
		height: 600px;

		&::before {
			content: "";
			position: absolute;
			top: 22%;
			left: 93%;
			transform: translateX(-50%);
			width: 50px;
			height: 50px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 12h14'/%3E%3Cpath d='M12 5l7 7-7 7'/%3E%3C/svg%3E");
			background-size: contain;
			animation: swipeRight 1.5s infinite;
			opacity: 1;
			transition: opacity 0.3s ease-out;
			z-index: 100;
		}

		&.interacted::before {
			opacity: 0;
			display: none;
		}

		& > div {
			position: absolute;
			width: 100%;
			top: 0;
			right: 0;
		}

		.description-image {
			width: 300px;
			height: 300px;
			margin: 0 auto;
			position: absolute;
			top: 0;
			right: 0;
			transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		}

		.article-description {
			position: absolute;
			width: 100%;
			top: 320px;
			right: 0;
		}

		.slide-enter,
		.slide-enter-active,
		.slide-exit,
		.slide-exit-active {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	.description-text {
		.description-text-link {
			font-size: 29px;
		}
		p {
			font-size: 18px;
		}
	}
	.inf-text {
		font-size: 14px !important;
	}
	.slide-enter {
		transform: translateX(100%);
		opacity: 1;
		transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	.slide-enter-active {
		transform: translateX(0);
		opacity: 1;
		transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
	// Exit animation
	.slide-exit {
		transform: translateX(0);
		opacity: 1;
		display: none;
	}

	.slide-exit-active {
		transform: translateX(-100%);
		opacity: 1;
		transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		display: none;
	}
	.slide-enter-active,
	.slide-exit-active {
		transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transform: translate3d(0, 0, 0);
		will-change: transform, opacity;
		backface-visibility: hidden;
		transform-style: preserve-3d;
	}
}

@media screen and (max-width: 992px) {
	.history-section {
		height: 100%;
	}
	.description-inf {
		align-items: flex-start;
		flex-direction: column;
	}
	.description-image {
		width: 300px;
		height: 300px;
	}
	.calendar-description {
		position: relative;
		flex-direction: column;
		gap: 40px;
		margin-top: 30px;
		padding: 0;
		transition: opacity 0.2s ease-out;
	}

	@keyframes swipeRight {
		0%,
		100% {
			transform: translateX(-60%);
		}
		50% {
			transform: translateX(-35%);
		}
	}

	.article-description {
		width: 100%;
	}
	.history-calendar {
		padding: 0 50px;
	}
	.description-text-link {
		font-size: 30px;
	}
	.small-font {
		font-size: 13px !important;
	}
}
@media only screen and (max-width: 1080px) and (min-width: 993px) {
	.calendar-description {
		padding: 0 50px;
	}
}
@media only screen and (min-width: 1200px) {
	.description-image {
		width: 350px !important;
		height: 350px !important;
	}
	.calendar-description {
		margin-top: 20px;
	}
	.calendar-events {
		max-width: 100%;
	}
}

@media screen and (min-width: 1920px) {
	.history-section {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	.history-heading {
		flex: 0 0 auto;
	}

	.history-calendar {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
	}

	.calendar-years {
		flex: 0 0 auto;
	}

	.button-events {
		width: 120px !important;
		height: 40px !important;
		padding: 0 16px !important;
		font-size: 0.8vw !important;
	}

	.small-font {
		font-size: 15px !important;
	}

	.calendar-events {
		flex: 0 0 auto;
		max-width: 100%;
	}

	.calendar-description {
		flex: 1 1 auto;
		overflow-y: auto;
		gap: 5vw;
		padding: 0 2vw;
	}

	.description-image {
		width: 30vw !important;
		height: 30vw !important;
		max-width: 550px;
		max-height: 550px;
	}

	.inf-text {
		font-size: 0.9vw !important;
	}

	.description-text-link {
		font-size: 2.2vw;
	}

	.description-text-p {
		font-size: 1.15vw;
	}
}

@media screen and (min-width: 2560px) {
	.history-section {
		padding: 2.5vw 0;
	}

	.history-heading h2 {
		font-size: 3vw;
	}

	.history-calendar {
		padding: 0 3vw;
	}

	.calendar-years {
		padding-top: 0.8vw;
	}

	.years {
		gap: 1vw;
		li {
			font-size: 1.4vw;
			padding-bottom: 0.25vw;
		}
	}

	.calendar-events {
		.events {
			gap: 0.55vw;
			li {
				padding: 0.55vw 0;
			}
		}
	}

	.button-events {
		width: 6.77vw !important;
		height: 2.44vw !important;
		padding: 0 1.08vw !important;
		font-size: 1.04vw !important;
		border-width: 5px !important;
	}
	.button-events {
		border-radius: 0.5vw !important;
	}
	.small-font {
		font-size: 0.85vw !important;
	}
	.calendar-description {
		gap: 4vw;
		padding: 0 4vw;
	}

	.description-image {
		width: 25vw !important;
		height: 25vw !important;
		max-width: 27vw;
		max-height: 27vw;
	}

	.article-description {
		width: 45%;
	}

	.description-text-link {
		font-size: 2.35vw;
	}

	.description-text-p {
		font-size: 1.4vw;
	}

	.inf-text {
		font-size: 1vw !important;
	}

	.icon-container {
		width: 1.6vw !important;
		height: 1.6vw !important;
	}
}

@media screen and (min-width: 3840px) {
	.history-section {
		padding: 2.9167vw 0;
	}

	.history-heading h2 {
		font-size: 102px;
	}

	.description-image {
		max-width: 1000px;
		max-height: 1000px;
	}

	.calendar-years {
		padding-top: 1.0157vw;
	}

	.years {
		gap: 1.3542vw;

		li {
			font-size: 1.3542vw;
			padding-bottom: 0.3385vw;

			&.active::after {
				height: 0.1355vw;
			}
		}
	}
	.calendar-events {
		.events {
			gap: 0.78vw;

			li {
				padding: 0.78vw 0;
			}
		}
	}
	.button-events {
		width: 6.7708vw !important;
		height: 2.4375vw !important;
		padding: 0 1.0833vw !important;
		font-size: 1.066vw !important;
		border-width: 8px !important;
	}

	.button-events {
		border-radius: 0.7vw !important;
	}

	.small-font {
		font-size: 0.88vw !important;
	}
	.inf-text {
		font-size: 0.9vw !important;
	}

	.description-text-link {
		font-size: 2.6vw;
	}

	.description-text-p {
		font-size: 1.3vw;
	}
	.icon-container {
		width: 45px !important;
		height: 45px !important;
	}
}
