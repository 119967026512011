// .text-align-center
//     text-align: center

.margin-top-90 {
    margin-top: 9rem;
}

.padding-top-50 {
    padding-top: 5rem;
}

.margin-top-40 {
    margin-top: 4rem;
}

.margin-bottom-15 {
    margin-top: 1.5rem;
}





