@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.gallery {
	background-color: var(--color-main-white);
	position: relative;
	// height: 600px;
	z-index: 100;
	transform: translateY(0); // Ensure the initial position is on-screen
	transition: transform 0.3s ease-in-out; // Smooth transition for transform
}

.gallery-container {
	padding: 0 32px;

	> *:last-child {
		padding: 0;
	}
}

.gallery-heading {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 30px;

	> *:last-child {
		margin-top: -32px;
	}
}

.gallery-text {
	font-family: "Graphik LCG Medium", sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: var(--color-gray);
	letter-spacing: -0.1px;
	line-height: 140%;
	text-align: center;
	margin-bottom: 0px;
}

.gallery-line {
	border-bottom-width: 1px;
	border-color: #d4d4d4;
	border-left-width: 1px;
	border-right-width: 1px;
	border-style: dashed;
	border-top-width: 1px;
	background-color: #fff;
	flex: none;
	height: 1px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.gallery-carousel {
	display: none; // Hide by default
}

.desktop-only {
	display: block;
	padding-bottom: .5vw;
}

.mobile-only {
	display: none;
}

@media screen and (max-width: 576px) {
	.gallery-container {
		padding-top: 30px;
	}
	.gallery-text {
		font-size: 16px !important;
	} 
}

@media (max-width: 768px) {
	.gallery-carousel {
		display: block; // Show on mobile
		position: relative;
		margin-top: 25px;
		width: 100%;
		height: 350px;

		.slick-slide {
			padding: 0 5px;
			height: 350px;
		}

		.slick-list {
			margin: 0;
			width: 100%;
		}

		img {
			width: 100%;
			height: 350px;
			object-fit: cover;
			border-radius: 8px;
		}

		// Dots styling
		.slick-dots {
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translateX(-50%);
			display: flex !important;
			justify-content: center;
			align-items: center;
			padding: 5px 10px;
			z-index: 10;

			li {
				width: 7px;
				height: 7px;
				margin: 0 3px;

				button {
					width: 7px;
					height: 7px;
					padding: 0;

					&:before {
						width: 7px;
						height: 7px;
						content: "";
						background-color: #ffffff;
						border-radius: 50%;
						opacity: 0.5;
					}
				}

				&.slick-active button:before {
					opacity: 1;
				}
			}
		}
	}

	.desktop-only {
		display: none;
	}

	.mobile-only {
		display: block;
	}

	.gallery-container {
		padding: 0;
		padding-bottom: 20px;
	}

	.gallery-heading {
		padding: 0 10px;
		gap: 20px;

		> *:last-child {
			margin-top: -20px;
		}
	}
	.gallery-text {
		font-size: 18px;
	}
}

@media only screen and (min-width: 769px) {
	.gallery {
		padding-bottom: 1.2vw;
	}
}

@media only screen and (min-width: 2560px) and (max-width: 3839px) {
	.gallery {
		height: 59vh;

		.gallery-container {
			padding: 0 1.25vw;
		}

		.gallery-heading {
			h1 {
				font-size: 3.4vw;
			}
			gap: 1.05vw;
			padding-top: 2.5vw;

			> *:last-child {
				margin-top: -1.25vw;
			}
		}

		.gallery-text {
			font-size: 1.95vw;
			letter-spacing: -0.0045vw;
		}
	}
	.ticker2-item {
		width: 31.25vw;
		height: 24.375vw;
	}
	.ticker2-container {
		height: 20.625vw;
	}
}

// 4K Resolution (3840px and up)
@media only screen and (min-width: 3840px) {
	.gallery {
		// height: 47vh; // 600px in 3840px
		.gallery-container {
			padding: 0 0.83vw; // 32px in 3840px
		}

		.gallery-heading {
			h1 {
				font-size: 2.71vw;
			}
			gap: 0.7vw; // 32px in 3840px
			padding-top: 2.9167vw; // 40px in 3840px

			> *:last-child {
				margin-top: -0.83vw; // -32px in 3840px
			}
		}

		.gallery-text {
			font-size: 1.3vw; // 20px in 3840px
			letter-spacing: -0.003vw; // -0.1px in 3840px
		}
	}
	.ticker2-item {
		width: 25vw; // Doubled from the original size (assuming original was 10.42vw)
		height: 19.5vw; // Doubled from the original size (assuming original was 7.81vw)
	}
	.ticker2-container {
		height: 16.5vw; // Match the height of ticker2-item
	}
}
