.about__container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	height: calc(100vh - 80px); // Adjust 80px to match your navbar's height
	// padding: 20px;
	background-color: var(--color-main-white);
	// @media screen and (max-width: 380px) {
	// 	height: 100% !important;
	// 	padding-top: 30% !important;
	// }
	@media screen and (max-width: 576px) {
		margin-top: 0;
		padding-top: 82px;
	}
	@media screen and (max-width: 768px) {
		height: 100%;
	}
}

.about__text {
	max-width: 95%;
	max-height: 90%;
	@media screen and (max-width: 768px) {
		padding: 10% 0;
	}
	p {
		font-family: "Graphik LCG Medium", sans-serif;
		font-size: 1.6vw;
		@media screen and (max-width: 1024px) {
			font-size: 2.2vw;
		}
		@media screen and (max-width: 768px) {
			font-size: 4vw;
		}
		@media screen and (max-width: 576px) {
			font-size: 6vw;
		}
	}
}

.about-page {
	.footer.visible {
		position: relative;
		transform: none;
	}

	.footer__container {
		display: block !important;
		height: 100%;
		@media screen and (max-width: 576px) {
			padding: 30px 0 30px !important;
		}
		@media screen and (max-width: 768px) {
			padding: 90px 0 30px;
		}
	}

	.footer .footer__content .footer__logo img {
		max-width: 60%;
	}
}